import { toast } from 'react-toastify'
import { createDefaultHeaders } from '../api'
import { getSession, urlWithAdminQuery } from '../auth'
import type { ServerUnit, Unit } from './types'

// Constants //////////////////////////////////////////////////////////////////

/** API URL */
// const API_URL = process.env.REACT_APP_API_URL

// Utility ////////////////////////////////////////////////////////////////////

/**
 * Process server response from get/list/update unit
 *
 * @param  {object} unNormalizedData - Server response
 * @returns {object} - Passed in object
 */
const handleUnitResponse = (unNormalizedData) => {
  return unNormalizedData
}

// API Calls //////////////////////////////////////////////////////////////////

/**
 * Create Unit
 *
 * @param {string} userId - User ID
 * @param {string} structureId - Structure ID the unit belongs to
 * @param {object} address - Unit address
 * @param {string} name - Unit name
 * @param {number} pipeDiameter - Pipe diameter
 * @param {string} pipeMaterial - Pipe material
 * @param {number} index - Unit index
 * @returns {Promise} - Created unit or error
 */
export const create = (
  userId: string,
  structureId: string,
  address: Object,
  name: string,
  index: number,
  unitFlag?: 'NON_BILLABLE' | 'COMMON_AREA' | 'SPLIT'[]
): Promise<Unit> => {
  const body = {
    name: name,
    pipe_diameter: '3_4INCH',
    pipe_material: 'COPPER-M',
    structure_id: structureId,
    ix: index,
    address,
    unit_flags: unitFlag ? unitFlag : []
  }
  return getSession().then((session) =>
    fetch(urlWithAdminQuery('/unit', session.adminEmail), {
      method: 'POST',
      body: JSON.stringify(body),
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    })
      .then((resp) => resp.json())
      .then(handleUnitResponse)
      .catch((err) => {
        console.error(err)
        toast.error(`Error in creating a Unit`)
        return err
      })
  )
}

/**
 * Update Unit in backend
 *
 * @param  {object} unit - Unit data to update
 * @returns {Promise} - Result of call to get unit
 */
export const edit = (unit: ServerUnit): Promise<Unit> => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/unit/${unit.id}`, session.adminEmail), {
      method: 'PUT',
      body: JSON.stringify(unit),
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    })
      .then((resp) => resp.json())
      .then(handleUnitResponse)
      .catch((err) => {
        console.error(err)
        toast.error(`Error in updating a Unit: ${unit.id}`)
        return err
      })
  )
}

/**
 * Delete unit
 *
 * @param  {string} unitId - Unit ID to delete
 * @returns {Promise} - Result of call to delete unit
 */
export const remove = (unitId: string): Promise<Response> => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/unit/${unitId}`, session.adminEmail), {
      method: 'DELETE',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    }).catch((err) => {
      console.error(err)
      toast.error(`Error in removing a Unit: ${unitId}`)
      return err
    })
  )
}
