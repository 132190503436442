import { Box, Stack, Typography } from '@mui/material'
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid'
import { StatusIcon } from '@/components/StatusIcon'

const AlertsTable = () => {
  const rows: GridRowsProp = [
    {
      id: 1,
      date: 'March 19th, 2025 07:50 (CST)',
      unitID: '1',
      device: 'TD-1',
      alert: 'Check pipe compatibility',
      description: 'Potential incorrect pipe type. Verify installation and check pipe material.'
    },
    {
      id: 2,
      date: 'March 19th, 2025 07:50 (CST)',
      unitID: '1',
      device: 'TD-1',
      alert: 'Check pipe compatibility',
      description: 'Potential incorrect pipe type. Verify installation and check pipe material.'
    }
  ]

  const columns: GridColDef[] = [
    { field: 'date', headerName: 'Date/Time', width: 250 },
    { field: 'unitID', headerName: 'Unit', width: 100 },
    {
      field: 'device',
      headerName: 'Device',
      width: 100,
      renderCell: (params) => (
        <Stack direction={'row'} gap={1} alignItems={'center'} marginTop={1}>
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#FCEBEC',
              color: '#DC3545',
              borderRadius: '8px',
              fontWeight: 'bold',
              padding: '4px 8px',
              fontSize: '0.875rem',
              textTransform: 'uppercase',
              boxShadow: 1,
              whiteSpace: 'nowrap'
            }}
          >
            <StatusIcon color={'#e53935'} type={'default'} />
          </Box>
          <Typography fontSize={12}>{params.value}</Typography>
        </Stack>
      )
    },
    { field: 'alert', headerName: 'Alert', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1 }
  ]

  return (
    <Box>
      {/* <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={0}>
          <Typography variant="h6" id="tableTitle" component="div">
            Fees
          </Typography>
        </Stack> */}
      <DataGrid
        rows={rows}
        columns={columns}
        sx={{
          boxShadow: 2,
          border: 0
        }}
      />
    </Box>
  )
}

export default AlertsTable
