import makeStyles from '@mui/styles/makeStyles'
import 'react-day-picker/dist/style.css'
import { useParams } from 'react-router-dom'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import FeesTable from './table'
import { useBills } from '@/Billing/useBills'
import { Bill, ILineItems } from '@/Billing/types'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  header: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 0,
    paddingLeft: 0,
    textAlign: 'left'
  },
  chartBody: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  chartLoader: {
    left: '20%',
    position: 'absolute',
    top: '50%',
    width: '60%'
  },
  selectedDays: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.secondary.main
  },
  editIcon: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
    opacity: 0.3,
    '&:hover': {
      opacity: 1
    }
  },
  prevNextButton: {
    height: 'fit-content'
  },
  nextButton: {
    marginLeft: theme.spacing(1)
  },
  prevButton: {
    marginRight: theme.spacing(1)
  }
}))

interface IProps {
  stripeConnect: boolean
}

export const FeesView = ({ stripeConnect }: IProps) => {
  const classes = useStyles()
  const { structureId } = useParams()
  if (!structureId) {
    throw new Error('MISSING_REQUIRED_ROUTE_DATA')
  }

  // Load bills
  const { bills = [], isLoading } = useBills(structureId)

  // Filtrar line items únicos con los tipos 'FLAT' y 'UNIT_FLAT'
  const getUniqueLineItems = (bills: Bill[]) => {
    const uniqueItems = new Map<string, ILineItems>()

    bills.forEach((bill) => {
      bill.lineItems.forEach((item) => {
        if ((item.type === 'FLAT' || item.type === 'UNIT_FLAT') && !uniqueItems.has(item.unit_id || item.description)) {
          uniqueItems.set(item.unit_id || item.description, item)
        }
      })
    })

    return Array.from(uniqueItems.values())
  }

  const filteredLineItems = getUniqueLineItems(bills)

  return (
    <ErrorBoundary>
      <div data-component-id={'STRUCTURE-VIEW'} className={classes.wrapper}>
        {!isLoading && filteredLineItems && structureId && (
          <div className={classes.container}>
            <div className={classes.header}>
              <div style={{ flexGrow: 1 }}>
                <FeesTable fees={filteredLineItems} />
              </div>
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}
