import { Address as AddressForm } from '../Create/Address'
import { LabelValue } from '../../components/LabelValue'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

// Types
import type { Address as AddressType } from '../../types'
import { SWLoadingButtonOutlined } from '@/components/SWLoadingButtonOutlined'
import { SWLoadingButton } from '@/components/LoadingButton'

// Dialog /////////////////////////////////////////////////////////////////////

const useEditDialogStyles: () => {
  wrapper: string
} = makeStyles({
  wrapper: {
    display: 'flex',
    width: '100%'
  }
})

type Props = {
  open: boolean
  onClose: () => void
  address: AddressType
  onUpdate: (value: any[]) => Promise<any>
  loading: boolean
  timeZone: string
}

const EditDialog = ({ open, onClose, address, onUpdate, loading = false, timeZone }: Props) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useEditDialogStyles()

  // State ////////////////////////////

  const [street, setStreet] = React.useState(address.street)
  const [street2, setStreet2] = React.useState(address.street2)
  const [city, setCity] = React.useState(address.city)
  const [state, setState] = React.useState(address.state)
  const [zip, setZip] = React.useState(address.zip)
  const [timeZoneValue, setTimeZone] = React.useState<string>(timeZone)

  React.useEffect(() => {
    setTimeZone(timeZone)
  }, [timeZone])

  // Component ////////////////////////

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={'xs'}
      aria-labelledby="structure-create-form-dialog"
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="structure-create-form-dialog">
        <Typography variant={'h4'} style={{ textAlign: 'center' }}>
          {'Edit Address'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div data-component-id={'STRUCTURE-ADDRESS-EDIT'} className={classes.wrapper}>
          <AddressForm
            title={'Edit Address'}
            street={street}
            street2={street2}
            city={city}
            state={state}
            zip={zip}
            onStreetChange={setStreet}
            onStreet2Change={setStreet2}
            onCityChange={setCity}
            onStateChange={setState}
            onZipChange={setZip}
            timeZone={timeZoneValue}
            onTimeZoneChange={(i) => setTimeZone(i)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <SWLoadingButtonOutlined onClick={onClose} color="primary">
          {'Cancel'}
        </SWLoadingButtonOutlined>
        <SWLoadingButton
          onClick={() => {
            onUpdate([
              {
                street,
                street2,
                city,
                state,
                zip
              },
              timeZoneValue
            ]).then(onClose)
          }}
          color="primary"
          autoFocus
          loading={loading}
        >
          {'Update'}
        </SWLoadingButton>
      </DialogActions>
    </Dialog>
  )
}

// Component //////////////////////////////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex'
  },
  button: {
    height: 'fit-content'
  },
  editIcon: {
    opacity: 0.3,
    paddingTop: 0,
    '&:hover': {
      opacity: 1
    }
  },
  inputRoot: {
    fontSize: '3rem',
    fontWeight: 400,
    letterSpacing: '0em',
    lineHeight: '1.167'
  }
})

export const Address = ({
  address,
  onUpdate,
  loading = false,
  timeZone
}: {
  address: AddressType
  onUpdate: (value: any[]) => Promise<any>
  loading: boolean
  timeZone: string
}): JSX.Element => {
  const classes = useStyles()
  const [isEditing, setIsEditing] = React.useState(false)
  return (
    <div className={classes.root}>
      <LabelValue
        label={''}
        value={`${address.street} ${address.street2 || ''}, ${address.city} ${address.state} ${
          address.zip
        }, Timezone: ${timeZone}`}
      />
      <IconButton size={'small'} onClick={() => setIsEditing(true)} className={classes.editIcon}>
        <EditIcon viewBox={'-4 -4 32 32'} />
      </IconButton>
      <EditDialog
        address={address}
        timeZone={timeZone}
        open={isEditing}
        onClose={() => setIsEditing(false)}
        onUpdate={onUpdate}
        loading={loading}
      />
    </div>
  )
}
