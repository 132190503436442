import { toast } from 'react-toastify'
import { createDefaultHeaders } from '../api'
import { getSession, urlWithAdminQuery } from '../auth'
import { addDays, differenceInCalendarDays, subSeconds } from 'date-fns'

import type { UnitUsage, DeviceUsageObject } from './types'

// API Calls //////////////////////////////////////////////////////////////////

// Get ////////////////////////////////

/**
 * Get usage from backend
 *
 * @param  {string} unitId - ID for specififc unit to get usage for
 * @param  {number} start - Unix timestamp for start of date range
 * @param  {number} end - Unix timestamp for end of date range
 * @returns {Promise} - Result of call to get usage
 */
export const get = (
  unitId: string,
  start: Date,
  end: Date,
  isElasticsearchOutage: boolean,
  timezone_key: string
): Promise<UnitUsage> => {
  if (isElasticsearchOutage) {
    const outageError = new Error(`Under maintenance! The usage system is currently unavailable.`)
    toast.error(outageError.message)
    return Promise.reject(outageError)
  }

  const MAX_DAYS = Number(process.env.REACT_APP_USAGE_CHART_DATEPICKER_MAX_DAYS_IN_PAST) || 365

  const diffInDays = differenceInCalendarDays(end, start)
  const numberOfRanges = Math.ceil(diffInDays / MAX_DAYS)
  const rangeArr = Array(numberOfRanges)
    .fill({})
    .map((_, idx, arr) => {
      const newStart = addDays(start, MAX_DAYS * idx).getTime()
      const newEnd = idx === arr.length - 1 ? end.getTime() : subSeconds(addDays(newStart, MAX_DAYS), 1).getTime()
      const dateStart = new Date(newStart)
      const dateEnd = new Date(newEnd)
      return { start: dateStart.toISOString().split('T')[0], end: dateEnd.toISOString().split('T')[0] }
    })
  const tZone = timezone_key === '' ? Intl.DateTimeFormat().resolvedOptions().timeZone : timezone_key

  return getSession().then((session) =>
    Promise.all(
      rangeArr.map((range) =>
        fetch(
          urlWithAdminQuery(
            `/unit/${unitId}/usage?start_date=${range.start}&end_date=${range.end}&timezone_key=${encodeURIComponent(
              tZone
            )}`,
            session.adminEmail
          ),
          {
            method: 'GET',
            headers: createDefaultHeaders({
              authToken: session.idToken.toString()
            })
          }
        )
          .then((resp) => resp.json())
          .catch((err) => {
            console.error(err)
            toast.error(`Error in getting a Usage for a Unit: ${unitId}`)
            return err
          })
      )
    ).then((resp) => {
      const devices: DeviceUsageObject[] = []

      resp.forEach((unit) => {
        unit.devices.forEach((device) => {
          const existingDevice = devices.find((d) => d.id === device.id)

          if (existingDevice) {
            existingDevice.daily_usages = [...existingDevice.daily_usages]
          } else {
            devices.push(device)
          }
        })
      })
      return {
        ...resp[0],
        devices
      }
    })
  )
}
