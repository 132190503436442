import { useCallback, useEffect, useState } from 'react'
import { saveAs } from 'file-saver'
import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { usePDF } from 'react-to-pdf'
import { convertArrayToCsvString, convertTimestampToTimeZone } from '../../util'
import { formatNumber, sortBy } from '../../util'
import { StructureBilling } from '@/Structures/types'
import { SWLoadingButtonOutlined } from '@/components/SWLoadingButtonOutlined'
import { SWLoadingButton } from '@/components/LoadingButton'
import { sendTenantReport } from '@/Structures/api'
import React from 'react'
import { Address, RateMethod } from '@/types'
import useMixPanel from '@/hooks/useMixPanel'
import { useBills } from '../useBills'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import moment from 'moment'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: theme.spacing(70)
  },
  dialogActionsRoot: {
    '@media print': {
      display: 'none'
    }
  }
}))

type Props = {
  bill: StructureBilling
  onClose: () => void
  open: boolean
  structureName: string
  autoDownload: 'csv' | 'pdf' | null
  needReportButton: boolean
  needSendBillButton: boolean
  structureAddress: Address
  timeZone: string
  stripeConnect: boolean
  invoices: any[]
  lineItems: any[]
  isPaymentReport: boolean
  billsSentTimestamp: number | null
}

interface IEventDetails {
  StartDate: string
  EndDate: string
  WaterUsage: number
  VolumeUnit: string
  Cost: number
  RateMethod: RateMethod
  PropertyName: string
  PropertyID: string
  BillID: string
  EffectiveRate: number
}

interface Device {
  serial_number: string
  name: string
  device_total_volume: number
}

interface Unit {
  name: string
  id: string
  unit_total_volume: number
  unit_cost_meter: number
  devices: Device[]
  stripe_last_status: string
  stripe_total: number
  stripe_currency: string
  stripe_invoice_id: string
  stripe_invoice_pdf_url: string
  stripe_invoice_url: string
  stripe_customer_id: string
  unit_id: string
}

interface IUpdatedBillingData {
  billing_id: string
  structure_id: string
  structure_name: string
  start_time: number
  end_time: number
  volume: number
  volume_units: string
  cost: number
  cost_units: string
  meter_total_volume: number
  rate_method: string
  effective_rate: number
  units: Unit[]
}

type IStatusCount = {
  [key in 'not_created' | 'draft' | 'open' | 'paid' | 'uncollectible' | 'void']?: number
}

export const Breakdown = ({
  bill,
  structureName,
  open,
  onClose,
  autoDownload,
  needReportButton,
  needSendBillButton,
  structureAddress,
  timeZone,
  stripeConnect,
  invoices,
  isPaymentReport,
  lineItems,
  billsSentTimestamp
}: Props): JSX.Element => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const classes = useStyles()
  const { toPDF, targetRef } = usePDF({ filename: `bill_report.pdf` })
  const formattedStartDate = convertTimestampToTimeZone(bill.start_time, timeZone)
  const formattedEndDate = convertTimestampToTimeZone(bill.end_time, timeZone)
  const title = `Tenant Report`
  const [loading, setLoading] = React.useState<boolean>(false)
  const [tz] = React.useState(timeZone === '' ? moment.tz.guess() : timeZone)
  const [isConfirmReport, setIsConfirmReport] = React.useState<boolean>(false)
  const { sendBills } = useBills(bill.structure_id)
  const [statusCount, setStatusCount] = React.useState<IStatusCount>()
  const [updatedBill, setUpdatedBill] = React.useState<IUpdatedBillingData>()
  const [alredyCSV, setAlredyCSV] = React.useState(false)

  const [eventDetails] = React.useState<IEventDetails>({
    StartDate: formattedStartDate,
    EndDate: formattedEndDate,
    WaterUsage: bill.volume,
    VolumeUnit: bill.volume_units,
    Cost: bill.cost,
    RateMethod: bill.rate_method,
    PropertyName: structureName,
    PropertyID: bill.structure_id,
    BillID: bill.billing_id,
    EffectiveRate: bill.effective_rate
  })
  const { trackEvent } = useMixPanel()

  React.useEffect(() => {
    if (updatedBill === undefined) {
      const data = mergeInvoicesToUnits(bill, invoices, lineItems)
      setUpdatedBill(data as any)
    }
  }, [bill, updatedBill, invoices, lineItems])

  const handleCsvDownload = useCallback(async () => {
    const data: any = updatedBill !== undefined ? updatedBill : bill

    let csv: string = await convertArrayToCsvString(
      data.units.map((row) => ({
        'Unit Name': row.name,
        'Structure Name': bill.structure_name,
        Volume: formatNumber(row.unit_total_volume, 3),
        'Volume Units': bill.volume_units,
        'Effective Rate': formatNumber(bill.effective_rate, 2),
        Cost: `$${formatNumber(row.unit_cost + row.unit_cost_meter, 2)}`,
        'Cost Units': bill.cost_units,
        Status: row.stripe_last_status,
        'Invoice URL': row.stripe_invoice_pdf_url
      }))
    )

    if (!stripeConnect)
      csv = await convertArrayToCsvString(
        data.units.map((row) => ({
          'Unit Name': row.name,
          'Structure Name': bill.structure_name,
          Volume: formatNumber(row.unit_total_volume, 3),
          'Volume Units': bill.volume_units,
          'Effective Rate': formatNumber(bill.effective_rate, 2),
          Cost: `$${formatNumber(row.unit_cost + row.unit_cost_meter, 2)}`,
          'Cost Units': bill.cost_units
        }))
      )

    saveAs(new Blob([csv], { type: 'text/plain;charset=utf-8' }), `${title.replace(/ /g, '_')}.csv`)
  }, [bill, title, updatedBill, stripeConnect])

  useEffect(() => {
    const handleAction = async () => {
      if (autoDownload === 'pdf') return toPDF()

      if (!alredyCSV && open && autoDownload === 'csv' && bill && updatedBill) {
        setAlredyCSV(true)
        try {
          await handleCsvDownload()
          await trackEvent('Bill Downloaded', eventDetails)
          onClose()
        } catch (error) {
          console.error('Error during CSV download:', error)
        }
      }
    }

    handleAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, autoDownload, alredyCSV, handleCsvDownload, trackEvent, eventDetails, onClose, bill, updatedBill])

  const handleSendReport = async () => {
    setLoading(true)
    await sendTenantReport(bill.billing_id)
    await trackEvent('Subscriber Usage Report Send', {
      PropertyID: eventDetails.PropertyID,
      PropertyName: eventDetails.PropertyName,
      BillID: eventDetails.BillID
    })
    setLoading(false)
    onClose()
  }

  const handleSendBill = async () => {
    setLoading(true)
    await trackEvent('Invoice Sent', {
      PropertyName: bill.structure_name,
      PropertyID: bill.structure_id,
      StartDate: convertTimestampToTimeZone(bill.start_time, timeZone),
      EndDate: convertTimestampToTimeZone(bill.end_time, timeZone)
    })
    await sendBills(bill.billing_id)
    setLoading(false)
    onClose()
  }

  const mergeInvoicesToUnits = (bill: StructureBilling, invoices, lineItems) => {
    const updatedBill = { ...bill }

    const splitItems = lineItems === undefined ? [] : lineItems.filter((item) => item.type === 'SPLIT')
    const flatItems = lineItems === undefined ? [] : lineItems.filter((item) => item.type === 'FLAT')
    const unitFlatItems = lineItems === undefined ? [] : lineItems.filter((item) => item.type === 'UNIT_FLAT')

    const totalUnits = updatedBill.units.length

    updatedBill.units = updatedBill.units.map((unit) => {
      let unitCost = 0

      splitItems.forEach((item) => {
        unitCost += item.cost / totalUnits
      })

      flatItems.forEach((item) => {
        unitCost += item.cost
      })

      unitFlatItems.forEach((item) => {
        if (item.unit_id === unit.id) {
          unitCost += item.cost
        }
      })

      return { ...unit, unit_cost: unitCost }
    })

    updatedBill.cost = updatedBill.units.reduce(
      (acc, unit: any) => acc + ((unit.unit_cost || 0) + (unit.unit_cost_meter || 0)),
      0
    )

    if (invoices && invoices.length > 0) {
      updatedBill.units = updatedBill.units.map((unit) => {
        const matchingInvoice = invoices.find((invoice) => invoice.unit_id === unit.id)
        return matchingInvoice ? { ...unit, ...matchingInvoice } : unit
      })
    }

    const statusCount = updatedBill.units.reduce((acc, unit: any) => {
      const status = unit.stripe_last_status || 'unknown'
      acc[status] = (acc[status] || 0) + 1
      return acc
    }, {})

    setStatusCount(statusCount)

    return updatedBill
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      aria-labelledby="form-dialog-title"
      maxWidth={isConfirmReport ? 'sm' : 'md'}
      fullWidth
      classes={{
        paper: classes.paper
      }}
    >
      <DialogContent ref={targetRef}>
        {!isConfirmReport && !needSendBillButton && (
          <Stack gap={1} marginBottom={2}>
            <Typography fontWeight={700} fontSize={'24px'}>
              Tenant Report
            </Typography>
            <Typography fontWeight={700} fontSize={'16px'}>
              {structureName}
            </Typography>
          </Stack>
        )}
        {!isConfirmReport && needSendBillButton && (
          <Stack gap={1} marginBottom={2}>
            <Typography fontWeight={700} fontSize={'24px'}>
              {stripeConnect ? 'Review and Confirm Tenant Invoices' : 'Billing Summary'}
            </Typography>
            <Typography fontWeight={700} fontSize={'16px'}>
              {structureName}
            </Typography>
            {stripeConnect && (
              <Typography fontWeight={400} fontSize={'14px'}>
                Please review the list of tenants scheduled to receive bills. Confirm the recipients and click 'Send'
                when you're ready.
              </Typography>
            )}
          </Stack>
        )}
        {!isConfirmReport && (
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            {!isPaymentReport && (
              <Card sx={{ flexGrow: 1 }}>
                <Box margin={1}>
                  <Stack alignItems="center">
                    <Typography textAlign="center" fontWeight={400} fontSize={'14px'}>
                      Billing Period
                    </Typography>
                    <Typography textAlign="center" fontWeight={700} fontSize={'24px'}>
                      {dayjs.unix(bill.start_time).tz(tz).format('MMM D')} -{' '}
                      {dayjs.unix(bill.end_time).tz(tz).format('MMM D')}
                    </Typography>
                  </Stack>
                </Box>
              </Card>
            )}
            {isPaymentReport && (
              <Card sx={{ flexGrow: 1 }}>
                <Box margin={1}>
                  <Stack alignItems="center">
                    <Typography textAlign="center" fontWeight={400} fontSize={'14px'}>
                      Total Tenant Invoices
                    </Typography>
                    <Typography textAlign="center" fontWeight={700} fontSize={'24px'}>
                      {bill.units.length}
                    </Typography>
                  </Stack>
                </Box>
              </Card>
            )}
            {!isPaymentReport && (
              <Card sx={{ flexGrow: 1 }}>
                <Box margin={1}>
                  <Stack alignItems="center">
                    <Typography textAlign="center" fontWeight={400} fontSize={'14px'}>
                      Total Volume
                    </Typography>
                    <Typography textAlign="center" fontWeight={700} fontSize={'24px'}>
                      {bill.volume}
                    </Typography>
                  </Stack>
                </Box>
              </Card>
            )}
            {isPaymentReport && (
              <Card
                sx={{
                  flexGrow: 1,
                  border: 1, // Define el grosor del borde
                  borderColor: '#4890CC', // Define el color del borde
                  borderStyle: 'solid' // Asegúrate de que el estilo del borde sea sólido
                }}
              >
                <Box margin={1}>
                  <Stack alignItems="center">
                    <Typography textAlign="center" fontWeight={400} fontSize="14px" color={'#4890CC'}>
                      Open Invoices
                    </Typography>
                    {statusCount && (
                      <Typography textAlign="center" fontWeight={700} fontSize="24px" color={'#4890CC'}>
                        {statusCount.open || 0}
                      </Typography>
                    )}
                  </Stack>
                </Box>
              </Card>
            )}
            {!isPaymentReport && (
              <Card sx={{ flexGrow: 1 }}>
                <Box margin={1}>
                  <Stack alignItems="center">
                    <Typography textAlign="center" fontWeight={400} fontSize={'14px'}>
                      Total Cost
                    </Typography>
                    {updatedBill && (
                      <Typography textAlign="center" fontWeight={700} fontSize={'24px'}>
                        ${formatNumber(updatedBill.cost, 2)}
                      </Typography>
                    )}
                  </Stack>
                </Box>
              </Card>
            )}
            {isPaymentReport && (
              <Card
                sx={{
                  flexGrow: 1,
                  border: 1, // Define el grosor del borde
                  borderColor: '#198754', // Define el color del borde
                  borderStyle: 'solid' // Asegúrate de que el estilo del borde sea sólido
                }}
              >
                <Box margin={1}>
                  <Stack alignItems="center">
                    <Typography textAlign="center" fontWeight={400} fontSize="14px" color={'#198754'}>
                      Paid
                    </Typography>
                    {statusCount && (
                      <Typography textAlign="center" fontWeight={700} fontSize="24px" color={'#198754'}>
                        {statusCount.paid || 0}
                      </Typography>
                    )}
                  </Stack>
                </Box>
              </Card>
            )}
            {isPaymentReport && (
              <Card
                sx={{
                  flexGrow: 1,
                  border: 1, // Define el grosor del borde
                  borderColor: '#DC3545', // Define el color del borde
                  borderStyle: 'solid' // Asegúrate de que el estilo del borde sea sólido
                }}
              >
                <Box margin={1}>
                  <Stack alignItems="center">
                    <Typography textAlign="center" fontWeight={400} fontSize="14px" color={'#DC3545'}>
                      Uncollectible Invoices
                    </Typography>
                    {statusCount && (
                      <Typography textAlign="center" fontWeight={700} fontSize="24px" color={'#DC3545'}>
                        {statusCount.uncollectible || 0}
                      </Typography>
                    )}
                  </Stack>
                </Box>
              </Card>
            )}

            {!isPaymentReport && (
              <Card sx={{ flexGrow: 1 }}>
                <Box margin={1}>
                  <Stack alignItems="center">
                    <Typography textAlign="center" fontWeight={400} fontSize={'14px'}>
                      Total Tenant Invoices
                    </Typography>
                    <Typography textAlign="center" fontWeight={700} fontSize={'24px'}>
                      {bill.units.length}
                    </Typography>
                  </Stack>
                </Box>
              </Card>
            )}
          </Stack>
        )}
        {!isConfirmReport && (
          <RenderTable
            bill={updatedBill}
            autoDownload={autoDownload}
            title={title}
            onClose={onClose}
            eventDetails={eventDetails}
            timeZone={timeZone}
            needStatus={needSendBillButton || needReportButton ? false : true}
            billsSentTimestamp={billsSentTimestamp}
            stripeConnect={stripeConnect}
          />
        )}
        {isConfirmReport && (
          <RenderConfirmReport
            propertyName={bill.structure_name}
            address={`${structureAddress.street}, ${structureAddress.state}, ${structureAddress.city}, ${structureAddress.zip}`}
            dateRange={`${formattedStartDate} - ${formattedEndDate}`}
          />
        )}
      </DialogContent>
      {!needReportButton && !needSendBillButton && !isConfirmReport && !isPaymentReport && (
        <DialogActions classes={{ root: classes.dialogActionsRoot }} sx={{ justifyContent: 'flex-end !important' }}>
          <SWLoadingButtonOutlined
            onClick={() => {
              handleCsvDownload()
              trackEvent('Bill Downloaded', eventDetails)
            }}
          >
            Download CSV
          </SWLoadingButtonOutlined>
          <SWLoadingButton
            onClick={() => {
              toPDF()
              trackEvent('Bill Downloaded', eventDetails)
            }}
          >
            Download PDF
          </SWLoadingButton>
        </DialogActions>
      )}

      {isPaymentReport && (
        <DialogActions classes={{ root: classes.dialogActionsRoot }} sx={{ justifyContent: 'flex-end !important' }}>
          <SWLoadingButton
            onClick={() => {
              handleCsvDownload()
              trackEvent('Bill Downloaded', eventDetails)
            }}
          >
            Download Payment Report
          </SWLoadingButton>
        </DialogActions>
      )}
      {needSendBillButton && stripeConnect ? (
        <DialogActions classes={{ root: classes.dialogActionsRoot }} sx={{ justifyContent: 'flex-end !important' }}>
          <SWLoadingButtonOutlined onClick={onClose}>Cancel</SWLoadingButtonOutlined>
          <SWLoadingButton
            loading={loading}
            onClick={() => {
              handleSendBill()
            }}
          >
            Send Bill
          </SWLoadingButton>
        </DialogActions>
      ) : (
        needSendBillButton &&
        !stripeConnect && (
          <DialogActions classes={{ root: classes.dialogActionsRoot }} sx={{ justifyContent: 'flex-end !important' }}>
            <SWLoadingButton onClick={onClose}>Close</SWLoadingButton>
          </DialogActions>
        )
      )}
      {needReportButton && !isConfirmReport && (
        <DialogActions classes={{ root: classes.dialogActionsRoot }} sx={{ justifyContent: 'flex-end !important' }}>
          <SWLoadingButton onClick={() => setIsConfirmReport(true)}>Send</SWLoadingButton>
        </DialogActions>
      )}
      {isConfirmReport && (
        <DialogActions
          classes={{ root: classes.dialogActionsRoot }}
          sx={{ justifyContent: 'space-between !important' }}
        >
          <SWLoadingButtonOutlined onClick={() => setIsConfirmReport(false)}>Back</SWLoadingButtonOutlined>
          <SWLoadingButton onClick={handleSendReport}>
            {loading ? (
              <>
                Loading...
                <CircularProgress size={20} color="inherit" sx={{ marginLeft: '10px' }} />
              </>
            ) : (
              'Confirm Send'
            )}
          </SWLoadingButton>
        </DialogActions>
      )}
    </Dialog>
  )
}

interface IRenderConfirmReport {
  propertyName: string
  address: string
  dateRange: string
}

const RenderConfirmReport = (props: IRenderConfirmReport) => {
  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h3" textAlign={'center'} fontWeight={'bold'}>
          Send usage reports
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" textAlign={'center'}>
          Water usage reports will be sent to the tenants for each unit. <br /> Please check the information below and
          confirm send.
        </Typography>
      </Box>
      <Box paddingTop={2}>
        <Stack>
          <Stack direction={'row'} spacing={1}>
            <Box fontWeight={'bold'}>Property: </Box>
            <Box>{props.propertyName}</Box>
          </Stack>
          <Stack direction={'row'} spacing={1}>
            <Box fontWeight={'bold'}>Address: </Box>
            <Box>{props.address}</Box>
          </Stack>
          <Stack direction={'row'} spacing={1}>
            <Box fontWeight={'bold'}>Date range: </Box>
            <Box>{props.dateRange}</Box>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  )
}

const RenderTable = ({
  bill,
  autoDownload,
  title,
  onClose,
  eventDetails,
  timeZone,
  needStatus = true,
  billsSentTimestamp,
  stripeConnect
}: {
  bill
  autoDownload: 'csv' | 'pdf' | null
  title: string
  onClose: Function
  eventDetails: IEventDetails
  timeZone: string
  needStatus?: boolean
  billsSentTimestamp: number | null
  stripeConnect: boolean
}) => {
  const { toPDF, targetRef } = usePDF({ filename: `${title.replace(/ /g, '_')}_report.pdf` })
  const [alredyExported, setAlredyExported] = useState<boolean>(false)
  const { trackEvent } = useMixPanel()
  useEffect(() => {
    if (autoDownload && !alredyExported) {
      if (autoDownload === 'pdf') {
        setAlredyExported(true)
        trackEvent('Bill Downloaded', eventDetails)
        onClose()
      }
    }
  }, [autoDownload, toPDF, alredyExported, onClose, trackEvent, eventDetails])

  const addDaysToDate = (unixTimestamp: number, days: number, timeZone?: string | null) => {
    const effectiveTimeZone = timeZone || dayjs.tz.guess()

    const inputDate = new Date(unixTimestamp * 1000)
    inputDate.setDate(inputDate.getDate() + days)

    const year = inputDate.getFullYear()
    const month = String(inputDate.getMonth() + 1).padStart(2, '0')
    const day = String(inputDate.getDate()).padStart(2, '0')

    return dayjs
      .tz(`${year}-${month}-${day}`, effectiveTimeZone)
      .set('hours', 23)
      .set('seconds', 59)
      .set('minutes', 59)
      .set('milliseconds', 0)
      .valueOf()
  }

  const renderStatusColor = (status: string): string => {
    switch (status) {
      case 'not_created':
        return '#E0E0E0'
      case 'draft':
        return '#D0E9FF'
      case 'open':
        return '#FFEFD0'
      case 'paid':
        return '#DFF7DF'
      case 'uncollectable':
        return '#FFD6D6'
      case 'void':
        return '#E4D6FF'
      default:
        return '#E0E0E0'
    }
  }

  return (
    <div ref={targetRef}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Unit Name</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Due</TableCell>
              <TableCell align="right">Water Usage</TableCell>
              <TableCell align="right">Billing Period</TableCell>
              {needStatus && stripeConnect && <TableCell align="right">Status</TableCell>}
              {needStatus && stripeConnect && <TableCell align="right">Invoice</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {bill.units.sort(sortBy('name')).map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{`$${formatNumber(row.unit_cost + row.unit_cost_meter, 2)}`}</TableCell>
                  <TableCell align="right">
                    {dayjs(addDaysToDate(billsSentTimestamp ? billsSentTimestamp : bill.end_time, 21, timeZone)).format(
                      'MM/DD/YYYY'
                    )}
                  </TableCell>
                  <TableCell align="right">{row.unit_total_volume}</TableCell>
                  <TableCell align="right">
                    {convertTimestampToTimeZone(bill.start_time, timeZone, true)} -{' '}
                    {convertTimestampToTimeZone(bill.end_time, timeZone, true)}
                  </TableCell>
                  {needStatus && row.stripe_last_status && (
                    <TableCell align="right">
                      <Box
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: renderStatusColor(row.stripe_last_status),
                          color: row.stripe_last_status === 'paid' ? '#198754' : '#5A5A5A',
                          borderRadius: '16px',
                          fontWeight: 'bold',
                          padding: '4px 12px',
                          fontSize: '0.875rem',
                          textTransform: 'uppercase',
                          boxShadow: 1,
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {row.stripe_last_status.replace('_', ' ').toUpperCase()}
                      </Box>
                    </TableCell>
                  )}
                  {needStatus && row.stripe_last_status && (
                    <TableCell align="right">
                      <IconButton
                        disabled={row.stripe_invoice_pdf_url === null ? true : false}
                        onClick={() => (window.location.href = row.stripe_invoice_pdf_url)}
                      >
                        <SimCardDownloadOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
