import * as React from 'react'
import { Link, useMatch } from 'react-router-dom'
import {
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Divider,
  Box,
  Stack,
  Typography,
  Menu,
  MenuItem,
  Button
} from '@mui/material'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import { sortBy } from '../../util'
import { createHomeRoute, createStructureRoute, createUnitRoute, routes } from '../../app-routes'
import AddIcon from '@mui/icons-material/Add'
import BusinessIcon from '@mui/icons-material/Business'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { NewReleases as NewReleasesIcon } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { MiniDrawer, DrawerHeader } from './components'
import StructureForm from '../../Structures/Create'
import { getUnitStatus } from '../../Structures/util'
import { FormDialog as AddUnitDialog } from '../../Units/FormDialog'
import { create as createUnit } from '../../Units/api'
import { ErrorBoundary } from '../ErrorBoundary'
import { drawerWidth } from './util'
import { useStructures, useStructure } from '../../Structures/useStructures'
import { getUserId } from '../../auth'
import { version } from '../../config'
// Types
import type { Structure } from '../../Structures/types'
import type { Unit, AddUnitFormValues } from '../../Units/types'
import { StatusIcon } from '../StatusIcon'
import useMixPanel from '@/hooks/useMixPanel'
import NaturePeopleIcon from '@mui/icons-material/NaturePeople'

// Constants //////////////////////////////////////////////////////////////////

const listItemStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none'
  },
  listItemText: {
    color: 'white'
  },
  listItemTextPrimary: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4)
  }
}))

// Structure List Item ////////////////////////////////////////////////////////

type StructureListItemProps = {
  structure: Structure
  isSelected: boolean
  numberOfUnits: number
  onClick: () => void
}

const StructureListItem = ({ structure, isSelected, numberOfUnits, onClick }: StructureListItemProps) => {
  const classes = listItemStyles()

  return (
    <ErrorBoundary>
      <Link to={createStructureRoute(structure.id)} className={classes.link} onClick={onClick}>
        <ListItem button key={structure.name} selected={isSelected}>
          <ListItemIcon>
            <BusinessIcon htmlColor="white" />
          </ListItemIcon>
          <ListItemText
            primary={structure.name}
            secondary={`${numberOfUnits} Units`}
            classes={{
              root: classes.listItemText,
              primary: classes.listItemTextPrimary,
              secondary: classes.listItemText
            }}
          />
          {isSelected ? <ExpandLess htmlColor="white" /> : <ExpandMore htmlColor="white" />}
        </ListItem>
      </Link>
    </ErrorBoundary>
  )
}

// Unit List Item /////////////////////////////////////////////////////////////

type UnitListItemProps = {
  structureId: string
  structureName: string
  unit: Unit
  isSelected: boolean
  sidebarOpen: boolean
}

interface IChipStatus {
  bgColor: string
  text: string
  color: string
  type: 'default' | 'leak' | 'hLeak'
}

const ChipStatus = ({ bgColor, text, color, type }: IChipStatus) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: bgColor,
        color: color,
        borderRadius: '8px',
        fontWeight: 'bold',
        padding: '4px 8px',
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        boxShadow: 1,
        whiteSpace: 'nowrap'
      }}
    >
      <Stack direction={'row'} gap={1}>
        <StatusIcon color={color} type={type} />
        {text !== '' && <Typography fontSize={12}>{text}</Typography>}
      </Stack>
    </Box>
  )
}

const UnitListItem = ({ structureId, structureName, unit, isSelected, sidebarOpen }: UnitListItemProps) => {
  const { trackEvent } = useMixPanel()
  const classes = listItemStyles()
  const unitStatus = getUnitStatus(unit)
  console.log(unit)

  const RenderDeviceStatus = (device, onlyIcon = false) => {
    switch (device.device_status) {
      case 'ERROR':
        return <ChipStatus bgColor="#FCEBEC" color="#DC3545" text={onlyIcon ? '' : device.name} type="default" />

      case 'OKAY':
        if (device.desired_usage_alert_enabled)
          return <ChipStatus bgColor="#E8F3EE" color="#198754" text={onlyIcon ? '' : device.name} type="default" />
        if (device.leak_enabled)
          return <ChipStatus bgColor="#E8F3EE" color="#198754" text={onlyIcon ? '' : device.name} type="default" />
        return <ChipStatus bgColor="#E8F3EE" color="#198754" text={onlyIcon ? '' : device.name} type="default" />

      case 'WARNING':
        return <ChipStatus bgColor="#6B5103" color="#FFF9E6" text={onlyIcon ? '' : device.name} type="default" />

      default:
        return ''
    }
  }
  return (
    <ErrorBoundary>
      <Link
        to={createUnitRoute(structureId, unit.id)}
        className={classes.link}
        onClick={() => {
          trackEvent('Unit Viewed', {
            PropertyID: structureId,
            PropertyName: structureName,
            UnitID: unit.id,
            UnitName: unit.name
          })
        }}
      >
        <ListItem
          button
          dense
          key={unit.name}
          className={sidebarOpen ? classes.nestedListItem : ''}
          selected={isSelected}
        >
          <ListItemIcon>
            {unit.unit_flags.length > 0 ? (
              <NaturePeopleIcon htmlColor="white" />
            ) : (
              <MeetingRoomIcon htmlColor="white" />
            )}
          </ListItemIcon>
          <ListItemText
            primary={unit.name}
            classes={{
              root: classes.listItemText,
              primary: classes.listItemTextPrimary
            }}
          />
          {unitStatus !== 'NO_DEVICES' && unit.devices.length > 2 ? (
            <Stack direction={'row'} gap={1}>
              {RenderDeviceStatus(unit.devices[0], true)}
              <Typography color={'white'}>+{unit.devices.length - 1}</Typography>
            </Stack>
          ) : (
            unit.devices && unit.devices.map((i) => RenderDeviceStatus(i, true))
          )}
        </ListItem>
      </Link>
    </ErrorBoundary>
  )
}

// Component //////////////////////////////////////////////////////////////////

const useStyles: () => {
  bottomToolbarButtonContainer: string
  bottomToolbarGradient: string
  bottomToolbarRoot: string
  drawer: string
  homeListItem: string
  link: string
  list: string
  listItemText: string
  nestedListItem: string
} = makeStyles((theme) => ({
  bottomToolbarRoot: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0
  },
  bottomToolbarGradient: {
    backgroundImage: 'linear-gradient(to bottom, rgba(86, 86, 86, 0), rgba(86, 86, 86, 1))',
    height: theme.spacing(2),
    width: '100%'
  },
  bottomToolbarButtonContainer: {
    backgroundColor: 'rgba(86, 86, 86, 1)',
    width: '100%',
    height: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  drawer: {
    maxWidth: drawerWidth,
    minWidth: drawerWidth,
    backgroundColor: theme.palette.grey[800] //'rgb(86, 86, 86)'
  },
  homeListItem: {
    height: theme.spacing(8)
  },
  link: {
    textDecoration: 'none'
  },
  list: {
    paddingTop: 0
  },
  listItemText: {
    color: 'white'
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4)
  }
}))

interface DrawerProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export const Drawer = ({ open, setOpen }: DrawerProps) => {
  const classes = useStyles()
  const hMatch = useMatch(routes.home)
  const sMatch = useMatch(routes.property)
  const uMatch = useMatch(routes.unit)
  const structureId = sMatch?.params?.structureId || uMatch?.params?.structureId || ''
  const unitId = uMatch?.params?.unitId
  const { structures } = useStructures()
  const { structure, mutate: updateStructure } = useStructure(structureId)

  const [isExpanded, setIsExpanded] = React.useState(true)
  const [isAddStructureFormOpen, setIsAddStructureFormOpen] = React.useState(false)
  const [type, setType] = React.useState<'UNIT' | 'COMMON_AREA'>('UNIT')
  const [isAddUnitOpen, setIsAddUnitOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const { trackEvent } = useMixPanel()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openMenuUnit = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSubmitAddUnit = async ({ name, pipeDiameter, pipeMaterial, unitFlag }: AddUnitFormValues) => {
    if (!structure || !structureId) return
    console.log('Add Unit', name, pipeDiameter, pipeMaterial, unitFlag)
    setLoading(true)
    const newUnit = await createUnit(
      await getUserId(),
      structureId,
      structure.address,
      name,
      structure.units.length,
      unitFlag
    )
    await updateStructure({
      ...structure,
      units: [...structure.units, newUnit]
    })
    trackEvent('Unit Created', {
      PropertyName: structure.name,
      PropertyID: structure.id,
      UnitName: newUnit.name,
      UnitID: newUnit.id
    })
    setIsAddUnitOpen(false)
    setLoading(false)
  }

  return (
    <ErrorBoundary>
      <MiniDrawer anchor={'left'} variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeftIcon htmlColor="#fff" />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <SimpleBar style={{ maxHeight: 'calc(100vh - 56px - 70px)' }}>
          <List className={classes.list}>
            <Link to={createHomeRoute()} className={classes.link}>
              <ListItem button selected={Boolean(hMatch)} className={classes.homeListItem}>
                <ListItemIcon>
                  <HomeWorkIcon htmlColor="white" />
                </ListItemIcon>
                <ListItemText primary={'Home'} className={classes.listItemText} />
              </ListItem>
            </Link>
            {structures
              ? structures.sort(sortBy('name')).map((s) => {
                  return (
                    <React.Fragment key={s.id}>
                      <StructureListItem
                        structure={s}
                        numberOfUnits={s.units.length}
                        onClick={() => {
                          if (s.id === structureId) {
                            setIsExpanded(!isExpanded)
                          } else {
                            setIsExpanded(true)
                            trackEvent('Property Viewed', { PropertyID: s.id, PropertyName: s.name })
                          }
                        }}
                        isSelected={s.id === structureId}
                      />
                      <Collapse in={structureId === s.id && isExpanded}>
                        <List>
                          {s.units &&
                            s.units
                              .filter(Boolean)
                              .sort((a, b) => {
                                const statusOrder = { ERROR: 1, WARNING: 2, OKAY: 3, NO_DEVICES: 3 }
                                const statusA = getUnitStatus(a)
                                const statusB = getUnitStatus(b)
                                if (statusOrder[statusA] !== statusOrder[statusB]) {
                                  return statusOrder[statusA] - statusOrder[statusB]
                                }
                                return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
                              })
                              .map((u) => {
                                return (
                                  <UnitListItem
                                    key={u.id}
                                    structureId={s.id}
                                    structureName={s.name}
                                    unit={u}
                                    isSelected={u.id === unitId}
                                    sidebarOpen={open}
                                  />
                                )
                              })}
                          <Button
                            sx={{ width: '100%', margin: 0, padding: 0, textTransform: 'capitalize' }}
                            onClick={handleClick}
                          >
                            <ListItem
                              // button
                              // onClick={() => setIsAddUnitOpen(true)}
                              className={open ? classes.nestedListItem : ''}
                            >
                              <ListItemIcon>
                                <AddIcon htmlColor="white" />
                              </ListItemIcon>
                              <ListItemText
                                primary="Add"
                                classes={{
                                  root: classes.listItemText
                                }}
                              />
                            </ListItem>
                          </Button>
                        </List>
                      </Collapse>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenuUnit}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button'
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleClose()
                            setIsAddUnitOpen(true)
                            setType('UNIT')
                          }}
                        >
                          <MeetingRoomIcon htmlColor="black" sx={{ marginRight: 2 }} />
                          Add Unit
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleClose()
                            setIsAddUnitOpen(true)
                            setType('COMMON_AREA')
                          }}
                        >
                          <NaturePeopleIcon htmlColor="black" sx={{ marginRight: 2 }} />
                          Add Other
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )
                })
              : null}
            <ListItem button onClick={() => setIsAddStructureFormOpen(true)}>
              <ListItemIcon>
                <AddIcon htmlColor="white" />
              </ListItemIcon>
              <ListItemText primary="Add Property" className={classes.listItemText} />
            </ListItem>
          </List>
        </SimpleBar>
        <Tooltip sx={{ position: 'absolute', bottom: 20, left: 20 }} title={version}>
          <NewReleasesIcon htmlColor="white" />
        </Tooltip>
        <StructureForm open={isAddStructureFormOpen} onClose={() => setIsAddStructureFormOpen(false)} />
        <AddUnitDialog
          addOrEdit={'ADD'}
          type={type}
          name={`${(structure?.units.length || 0) + 1}`}
          pipeDiameter={1}
          pipeMaterial={'COPPER-M'}
          open={isAddUnitOpen}
          onClose={() => setIsAddUnitOpen(false)}
          onSubmit={handleSubmitAddUnit}
          loading={loading}
        />
      </MiniDrawer>
    </ErrorBoundary>
  )
}
