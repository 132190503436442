import { createDefaultHeaders, throwIfError } from '../api'
import { getSession, urlWithAdminQuery } from '../auth'
import { log } from '../util'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'

import type { Address } from '../types'
import type { Structure } from './types'

// Constants //////////////////////////////////////////////////////////////////

/** API URL */
// const API_URL = process.env.REACT_APP_API_URL

// Utility ////////////////////////////////////////////////////////////////////

/**
 * `log.info` with bound category
 *
 * @param  {string} message - Log message
 * @param  {object} data    - Log data
 * @param  {string} level   - Log level
 * @returns {void}
 */
const info = (message: string, data?: Object, level?: Sentry.Severity): void =>
  log.info('STRUCTURE::API', message, data, level)

/**
 * Process server response from get/list/update structure
 *
 * @param  {object} unNormalizedData - Server response
 * @returns {object} - Passed in object
 */
const handleStructureResponse = (unNormalizedData) => {
  return unNormalizedData
}

// API Calls //////////////////////////////////////////////////////////////////

// Create /////////////////////////////

export const create = (userId: string, structure: { name: string; address: Address }): Promise<Structure> => {
  return getSession().then((session) => {
    const headers = createDefaultHeaders({
      authToken: session.idToken.toString()
    })
    info('Starting call to create structure', {
      userId,
      structure,
      requestId: headers.get('request-id')
    })
    return fetch(urlWithAdminQuery(`/structure`, session.adminEmail), {
      method: 'POST',
      body: JSON.stringify(structure),
      headers
    })
      .then(throwIfError)
      .then((resp) => resp.json())
      .then(handleStructureResponse)
      .catch((err) => {
        const reqId = err.headers.get('request-id')
        const newErr = new Error(`Error creating structure [requestId: ${reqId}]`)
        toast.error(newErr.message)
        log.error(newErr)
        throw newErr
      })
  })
}

/**
 * Get Structures from backend
 *
 * @returns {Promise} - Result of call to get structure
 */
export const list = (): Promise<Structure[]> => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery('/structure', session.adminEmail), {
      method: 'GET',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    })
      .then(throwIfError)
      // Get JSON
      .then((resp) => resp.json())
      .then(handleStructureResponse)
      .catch((err) => {
        const reqId = err.headers.get('request-id')
        const newErr = new Error(`Error getting structures [requestId: ${reqId}]`)
        toast.error(newErr.message)
        log.error(newErr)
        throw newErr
      })
  )
}

// Edit ///////////////////////////////

/**
 * Edit structure
 *
 * @param  {object} structure - Structure data to update
 * @returns {Promise} - Result of call to update structure
 */
export const edit = (structure: Structure): Promise<Structure> => {
  const body = structure

  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/structure/${structure.id}`, session.adminEmail), {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    })
      .then(throwIfError)
      .then((resp) => resp.json())
      .then(handleStructureResponse)
      .catch((err) => {
        const reqId = err.headers.get('request-id')
        const newErr = new Error(`Error editing structure [requestId: ${reqId}]`)
        toast.error(newErr.message)
        log.error(newErr)
        throw newErr
      })
  )
}

// Delete /////////////////////////////

/**
 * Delete structure
 *
 * @param  {string} structureId - Structure ID to delete
 * @returns {Promise} - Result of call to delete structure
 */
export const remove = (structureId: string): Promise<Response> => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/structure/${structureId}`, session.adminEmail), {
      method: 'DELETE',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    })
      .then(throwIfError)
      .catch((err) => {
        const reqId = err.headers.get('request-id')
        const newErr = new Error(`Error deleting structure [requestId: ${reqId}]`)
        toast.error(newErr.message)
        log.error(newErr)
        throw newErr
      })
  )
}

/**
 * Send Bill Report to Tenant from backend
 *
 * @returns {Promise} - Result of call to Send Bill Report to Tenant
 */
export const sendTenantReport = (idBill: string) => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/billing/${idBill}/sendUsageReports`, session.adminEmail), {
      method: 'POST',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    }).catch((err) => {
      const newErr = new Error(`Error sending report [Error: ${err.body}]`)
      toast.error(newErr.message)
      log.error(newErr)
    })
  )
}

export const sendBill = (idBill: string, days_until_due: number) => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/billing/${idBill}/sendBills`, session.adminEmail), {
      method: 'POST',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      }),
      body: JSON.stringify({ days_until_due: 21 })
    }).catch((err) => {
      const newErr = new Error(`Error sending report [Error: ${err.body}]`)
      toast.error(newErr.message)
      log.error(newErr)
    })
  )
}

/**
 * Get Suscribers from backend
 *
 * @returns {Promise} - Result of call to get Suscribers
 */
export const getSuscribers = (idProperty: string) => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/subscriber?property_id=${idProperty}`, session.adminEmail), {
      method: 'GET',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    })
      .then(throwIfError)
      .then((resp) => resp.json())
      .catch((err) => {
        const newErr = new Error(`Error getting subscribers [Error: ${err.body}]`)
        toast.error(newErr.message)
        log.error(newErr)
      })
  )
}
