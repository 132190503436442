import makeStyles from '@mui/styles/makeStyles'
import 'react-day-picker/dist/style.css'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import AlertsTable from './table'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  header: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 0,
    paddingLeft: 0,
    textAlign: 'left'
  },
  chartBody: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  chartLoader: {
    left: '20%',
    position: 'absolute',
    top: '50%',
    width: '60%'
  },
  selectedDays: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.secondary.main
  },
  editIcon: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
    opacity: 0.3,
    '&:hover': {
      opacity: 1
    }
  },
  prevNextButton: {
    height: 'fit-content'
  },
  nextButton: {
    marginLeft: theme.spacing(1)
  },
  prevButton: {
    marginRight: theme.spacing(1)
  }
}))

interface IProps {
  stripeConnect: boolean
}

export const AlertsView = ({ stripeConnect }: IProps) => {
  const classes = useStyles()

  return (
    <ErrorBoundary>
      <div data-component-id={'STRUCTURE-VIEW'} className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.header}>
            <div style={{ flexGrow: 1 }}>
              <AlertsTable />
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}
