export const StatusIcon = ({ color, type = 'default' }: { color: string; type?: 'default' | 'leak' | 'hLeak' }) => {
  if (type === 'default')
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.66663 12.5V7.5C1.66663 4.73857 3.9052 2.5 6.66663 2.5H13.3333C16.0947 2.5 18.3333 4.73857 18.3333 7.5V12.5C18.3333 15.2614 16.0947 17.5 13.3333 17.5H6.66663C3.9052 17.5 1.66663 15.2614 1.66663 12.5Z"
          stroke={color}
        />
        <path
          d="M12.5 7.5C12.5 7.5 13.3333 8.4375 13.3333 10C13.3333 11.5625 12.5 12.5 12.5 12.5"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M10 10.0092L10.0083 10" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M14.1666 5.83398C14.1666 5.83398 15.8333 7.32208 15.8333 10.0007C15.8333 12.6792 14.1666 14.1673 14.1666 14.1673"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.49996 7.5C7.49996 7.5 6.66663 8.4375 6.66663 10C6.66663 11.5625 7.49996 12.5 7.49996 12.5"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.83329 5.83398C5.83329 5.83398 4.16663 7.32208 4.16663 10.0007C4.16663 12.6792 5.83329 14.1673 5.83329 14.1673"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    )

  if (type === 'leak')
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.66675 12.5V7.5C1.66675 4.73857 3.90532 2.5 6.66675 2.5H13.3334C16.0948 2.5 18.3334 4.73857 18.3334 7.5V12.5C18.3334 15.2614 16.0948 17.5 13.3334 17.5H6.66675C3.90532 17.5 1.66675 15.2614 1.66675 12.5Z"
          stroke="#6B5103"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.99925 6.66602C9.99925 6.66602 12.4993 9.16052 12.4995 10.7381C12.4998 12.1178 11.3793 13.2381 9.99975 13.2381C8.62017 13.2381 7.51 12.1178 7.5 10.7381C7.50816 9.15968 9.99925 6.66602 9.99925 6.66602Z"
          stroke="#6B5103"
          stroke-miterlimit="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    )

  if (type === 'hLeak')
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.66675 12.5V7.5C1.66675 4.73857 3.90532 2.5 6.66675 2.5H13.3334C16.0948 2.5 18.3334 4.73857 18.3334 7.5V12.5C18.3334 15.2614 16.0948 17.5 13.3334 17.5H6.66675C3.90532 17.5 1.66675 15.2614 1.66675 12.5Z"
          stroke="#6B5103"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.4992 6.66602C11.4992 6.66602 13.9993 9.16052 13.9995 10.7381C13.9998 12.1178 12.8793 13.2381 11.4997 13.2381C10.1202 13.2381 9.01 12.1178 9 10.7381C9.00816 9.15968 11.4992 6.66602 11.4992 6.66602Z"
          fill="#FFF9E6"
          stroke="#6B5103"
          stroke-miterlimit="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.49925 6.66602C8.49925 6.66602 10.9993 9.16052 10.9995 10.7381C10.9998 12.1178 9.87933 13.2381 8.49975 13.2381C7.12017 13.2381 6.01 12.1178 6 10.7381C6.00816 9.15968 8.49925 6.66602 8.49925 6.66602Z"
          fill="#FFF9E6"
          stroke="#6B5103"
          stroke-miterlimit="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    )

  return <></>
}
