/** @file Shared app functionality */

import * as jsonexport from 'jsonexport/dist'
import * as Sentry from '@sentry/browser'
import moment from 'moment-timezone'
import type { DateStr } from './types'

/**
 * Safe way to access Object.hasOwnProperty
 *
 * @param  {object}  obj  - Object to check
 * @param  {string}  prop - Key to check for existence of
 * @returns {boolean} - Whether or not `obj` has a property `key`
 */
export const hasOwnPropertySafe = (obj: Object, prop: string): Boolean =>
  Object.prototype.hasOwnProperty.call(obj, prop)

/**
 * Convert array to object
 *
 * @param  {object[]} arr - Array to convert
 * @returns {object} - Object based on array
 */
export const arrToObj = (arr: { id: string }[]) => arr.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})

/**
 * Sort array of objects based on specific property
 *
 * @param  {string} key - Property to sort by
 * @returns {number} - Sort order
 */
export const sortBy =
  (key: string) =>
  (a: any, b: any): number => {
    let order = 0

    if (hasOwnPropertySafe(a, key) && hasOwnPropertySafe(b, key)) {
      if (a[key] < b[key]) {
        order = -1
      } else if (a[key] > b[key]) {
        order = 1
      }
    }

    return order
  }

/** Add value to object property, creating it if it doesn't exist */
export const addCreate = (obj: object, key: string, val: number) => {
  if (obj[key]) {
    obj[key] += val
  } else {
    obj[key] = val
  }
}

/**
 * Format a passed in number (adds commas and truncates decimal places)
 *
 * @param  {number} number        - Number to format
 * @param  {[number]} decimalPlaces - Number of decimal places to allow
 * @returns {string} - Formatted number (as string)
 */
export const formatNumber = (number: number, decimalPlaces: number = 0): string =>
  new Intl.NumberFormat('en-US', {
    notation: 'standard',
    maximumFractionDigits: decimalPlaces
  }).format(number)

/**
 * Convert date string in local time zone (eg: YYYY-MM-DD) to Date
 * The parameter values (year, month, day) are all evaluated against the local time zone, rather than UTC
 *
 * @param  {string} dateStr - String to convert
 * @returns {Date} - String converted to date
 */
export const dateStringToDate = (dateStr: DateStr): Date => {
  const [y, m, d] = dateStr.split('-')
  return new Date(Number(y), Number(m) - 1, Number(d))
}

/**
 * Convert a standard date to a date string
 *
 * @param {Date} date - Date
 * @returns {string} - Date string
 */
export const dateToDateString = (date: Date): DateStr => {
  const yStr = date.getFullYear()
  const mStr = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1)
  const dStr = (date.getDate() < 10 ? '0' : '') + date.getDate()

  return `${yStr}-${mStr}-${dStr}`
}

/**
 * Convert a timestamp from the server into a Date
 *
 * @param {number} timestamp - Timestamp
 * @returns {Date} - JS Date version of timestamp
 */
export const timestampToDate = (timestamp: number) => new Date(timestamp * 1000)

/**
 * Convert an array to a CSV string
 *
 * @param  {object[]} arr - Array of objects
 * @returns {Promise} - Promise that resolves to CSV string
 */
export const convertArrayToCsvString = (arr: Object[]): Promise<string> =>
  new Promise((resolve, reject) => {
    jsonexport(arr, function (err, csv) {
      if (err) {
        return reject(err)
      }
      return resolve(csv)
    })
  })

/**
 * Wrapper for `console` that pushes messages to external systems
 */
export const log = {
  ...console,
  info: (category: string, message: string, data?: Object, level: Sentry.Severity = Sentry.Severity.Info) => {
    Sentry.addBreadcrumb({ category, message, data, level })
    console.info({ category, message, data, level })
  },
  error: (...args: any[]) => {
    Sentry.captureException(args)
    console.error(args)
  }
}

/**
 * Converts a UTC Unix timestamp to a formatted date string in the specified timezone
 *
 * @param timestamp - Unix timestamp in seconds (UTC)
 * @param timeZone - IANA timezone string (e.g. "America/New_York")
 * @param flatFormat - If true, returns date in MM/DD/YY format
 * @param timeType - If 'start', sets time to 00:00:00; if 'end', sets time to 23:59:59
 * @returns Formatted date string
 * @throws Error if timestamp or timezone is invalid
 *
 * @example
 * // Returns "January 1st, 2023 (EST)"
 * convertTimestampToTimeZone(1672589100, "America/New_York", false)
 *
 * // Returns "01/01/23"
 * convertTimestampToTimeZone(1672589100, "America/New_York", true)
 */
export const convertTimestampToTimeZone = (
  timestamp: number,
  timeZone?: string,
  flatFormat?: boolean,
  timeType?: 'start' | 'end'
): string => {
  // Constants for date formats
  const FLAT_DATE_FORMAT = 'MM/DD/YY'

  // Validate timestamp
  if (!timestamp || isNaN(timestamp)) {
    throw new Error('Invalid timestamp provided')
  }

  // Create moment instance from unix timestamp
  let date = moment.unix(timestamp)

  // Validate resulting date
  if (!date.isValid()) {
    throw new Error('Invalid date generated from timestamp')
  }

  // Adjust time based on timeType before timezone conversion
  if (timeType === 'start') {
    date = date.startOf('day')
  } else if (timeType === 'end') {
    date = date.endOf('day')
  }

  // Clean and validate timezone if provided
  const cleanTimeZone = timeZone?.trim()
  if (cleanTimeZone && !moment.tz.zone(cleanTimeZone)) {
    throw new Error(`Invalid timezone: ${cleanTimeZone}`)
  }

  // Format date with ordinal numbers (1st, 2nd, 3rd, etc)
  const formatWithOrdinal = (momentDate: moment.Moment, timeZoneAbbr: string | undefined): string => {
    try {
      const day = momentDate.format('D')
      const dayWithSuffix = momentDate.format('Do').replace(day, day)
      const month = momentDate.format('MMMM')
      const year = momentDate.format('YYYY')

      const formattedTimeZone = timeZoneAbbr ? ` (${timeZoneAbbr})` : ''

      return `${month} ${dayWithSuffix}, ${year}${formattedTimeZone}`.trim()
    } catch (error) {
      throw new Error(`Error formatting date: ${error}`)
    }
  }

  try {
    // Use provided timezone or fallback to browser's timezone
    const effectiveTimeZone = cleanTimeZone || moment.tz.guess()
    const timeZoneAbbr = moment.tz.zone(effectiveTimeZone)?.abbr(date.valueOf())

    // Convert date to target timezone
    const localDate = date.tz(effectiveTimeZone)

    // Return either flat format or formatted with ordinal
    if (flatFormat) {
      return `${localDate.format(FLAT_DATE_FORMAT)}`.trim()
    }
    return formatWithOrdinal(localDate, timeZoneAbbr)
  } catch (error) {
    throw new Error(`Error converting timestamp to timezone: ${error}`)
  }
}
