/** @file Dialog to add a single unit */
import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { SWLoadingButton } from '@/components/LoadingButton'

import type { PipeDiameter, PipeMaterial } from '../types'

type Props = {
  name: string
  pipeDiameter: PipeDiameter
  pipeMaterial: PipeMaterial
  open: boolean
  onClose: () => void
  addOrEdit: 'ADD' | 'EDIT'
  type: 'UNIT' | 'COMMON_AREA'
  onSubmit: (data: {
    name: string
    pipeDiameter: PipeDiameter
    pipeMaterial: PipeMaterial
    unitFlag?: 'NON_BILLABLE' | 'COMMON_AREA' | 'SPLIT'[]
  }) => void
  loading: boolean
}

/**
 * Create Unit dialog
 *
 * @param {object} props - Component properties
 * @returns {React.Component} - Create Unit dialog
 */
export const FormDialog = (props: Props) => {
  const [localName, setLocalName] = React.useState(props.name)
  const [unitFlag, setUnitFlag] = React.useState<'NON_BILLABLE' | 'COMMON_AREA' | 'SPLIT'>('NON_BILLABLE')
  const [openSplitWarning, setOpenSplitWarning] = React.useState(false)

  const handleSubmit = () => {
    let body = {
      name: localName,
      pipeDiameter: props.pipeDiameter,
      pipeMaterial: props.pipeMaterial
    }
    if (props.type === 'COMMON_AREA') body['unitFlag'] = ['COMMON_AREA', unitFlag]

    if (unitFlag === 'SPLIT') {
      setOpenSplitWarning(true)
    } else {
      props.onSubmit(body)
    }
  }

  const handleConfirmSplit = () => {
    setOpenSplitWarning(false)

    let body = {
      name: localName,
      pipeDiameter: props.pipeDiameter,
      pipeMaterial: props.pipeMaterial
    }
    if (props.type === 'COMMON_AREA') body['unitFlag'] = ['COMMON_AREA', unitFlag]
    props.onSubmit(body)
  }

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>{`${props.addOrEdit === 'ADD' ? 'Add' : 'Edit'} ${
          props.type === 'UNIT' ? 'Unit' : 'Common Area'
        }`}</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            {props.name !== undefined && (
              <TextField
                label={'Name'}
                placeholder={'Name'}
                helperText={'eg: "1", "205-b"'}
                required={true}
                value={localName}
                sx={{ marginTop: 2 }}
                onChange={(e) => {
                  setLocalName(e.target.value)
                }}
              />
            )}
            {props.type === 'COMMON_AREA' && (
              <FormControl fullWidth>
                <InputLabel id="unitflag">Bill Model</InputLabel>
                <Select
                  labelId="unitflag"
                  value={unitFlag}
                  label="Bill Model"
                  onChange={(e) => setUnitFlag(e.target.value as 'NON_BILLABLE' | 'COMMON_AREA' | 'SPLIT')}
                >
                  <MenuItem value={'NON_BILLABLE'}>Non Billable</MenuItem>
                  <MenuItem value={'SPLIT'}>Split</MenuItem>
                </Select>
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <SWLoadingButton onClick={handleSubmit} loading={props.loading}>
            {props.addOrEdit === 'ADD' ? 'Add' : 'Save'}
          </SWLoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openSplitWarning} onClose={() => setOpenSplitWarning(false)}>
        <DialogTitle>Warning: Split Billing</DialogTitle>
        <DialogContent>
          This is not recommended for residential properties and can be prohibited. Please confirm this is a commercial
          property where you'd like to split the common water costs among tenants.
        </DialogContent>
        <DialogActions>
          <Button variant={'outlined'} onClick={() => setOpenSplitWarning(false)}>
            Cancel
          </Button>
          <SWLoadingButton onClick={handleConfirmSplit}>Confirm</SWLoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
