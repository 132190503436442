import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { SWLoadingButton } from '../LoadingButton'
import { SWLoadingButtonOutlined } from '../SWLoadingButtonOutlined'

const DeleteConfirmModal = ({ open, onClose, onConfirm, title, loading = false, sub = '' }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{sub}</DialogContent>
      <DialogActions>
        <SWLoadingButtonOutlined onClick={onClose}>Cancel</SWLoadingButtonOutlined>
        <SWLoadingButton onClick={onConfirm} autoFocus loading={loading}>
          Confirm
        </SWLoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteConfirmModal
