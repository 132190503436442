import makeStyles from '@mui/styles/makeStyles'
import 'react-day-picker/dist/style.css'
import { useParams } from 'react-router-dom'
import { useBills } from '../../Billing/useBills'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import { Billing } from '../View/Billing'
import { useStructure } from '../useStructures'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  header: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 0,
    paddingLeft: 0,
    textAlign: 'left'
  },
  chartBody: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  chartLoader: {
    left: '20%',
    position: 'absolute',
    top: '50%',
    width: '60%'
  },
  selectedDays: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.secondary.main
  },
  editIcon: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
    opacity: 0.3,
    '&:hover': {
      opacity: 1
    }
  },
  prevNextButton: {
    height: 'fit-content'
  },
  nextButton: {
    marginLeft: theme.spacing(1)
  },
  prevButton: {
    marginRight: theme.spacing(1)
  }
}))

interface IProps {
  stripeConnect: boolean
}

export const UtilityWaterBills = (props: IProps): JSX.Element => {
  const classes = useStyles()

  const { structureId } = useParams()
  if (!structureId) {
    throw new Error('MISSING_REQUIRED_ROUTE_DATA')
  }

  // Data fetching ////////////////////

  const { structure } = useStructure(structureId)

  // Load bills
  const { bills = [], isLoading } = useBills(structureId)

  return (
    <ErrorBoundary>
      <div data-component-id={'STRUCTURE-VIEW'} className={classes.wrapper}>
        {structure && (
          <div className={classes.container}>
            <div className={classes.header}>
              <div style={{ flexGrow: 1 }}>
                <Billing
                  structureId={structureId}
                  structureName={structure.name}
                  bills={bills}
                  isLoading={isLoading}
                  defaultRateMethod={structure.rate_method}
                  structureAddress={structure.address}
                  timeZone={structure.timezone_key}
                  stripeConnect={props.stripeConnect}
                  units={structure.units}
                  volumeUnits={structure.volume_units}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}
