import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import React from 'react'
import { ILineItems } from '@/Billing/types'

const FeesTable = ({ fees }: { fees: ILineItems[] }) => {
  return (
    <Paper>
      <Box margin={3}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={0}>
          <Typography variant="h6" id="tableTitle" component="div">
            Fees
          </Typography>
        </Stack>
        <Box marginTop={5}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell align="right">Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fees &&
                  fees.map((e, i) => {
                    return (
                      <TableRow key={e.type + i}>
                        <TableCell>{e.description}</TableCell>
                        <TableCell>{e.type.replace('_', ' ')}</TableCell>
                        <TableCell align="right">${e.cost}</TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Paper>
  )
}

export default FeesTable
