import { v4 as uuid } from 'uuid'
import { format, parseISO } from 'date-fns'
import { Address } from '../types'
import { addCreate } from '../util'

import type { DeviceStatus } from '@/types'
import type { Unit } from '../Units/types'
import type { UnitUsage } from '../Usage/types'

/**
 * Sort array of objects, each containing an `index` property
 *
 * @param  {object} a - First item to sort
 * @param  {object} b - Second item to sort
 * @returns {number} - Sort order for a and b
 */
export const sortByIndex = (a: { index: number }, b: { index: number }) => {
  let sort = 0

  if (a.index < b.index) {
    sort = -1
  } else if (b.index < a.index) {
    sort = 1
  }

  return sort
}

/**
 * Create Units
 *
 * @param  {number} numberOfUnits   - Number of units to create
 * @param  {number} defaultPipeSize - Default pipe size
 * @param  {number} defaultPipeType - Default pipe type
 * @returns {object[]} - Array of units
 */
export const createUnits = (numberOfUnits: number): Unit[] =>
  Array.from({ length: numberOfUnits }, (_, idx) => ({
    id: uuid(),
    name: String(idx + 1),
    index: idx,
    devices: [],
    deviceIds: [],
    address: { street: '', street2: '', city: '', state: '', zip: '' },
    unit_flags: []
  }))

/**
 * Determine if a single unit is valid (based on user entered data)
 *
 * @param  {object}  unit - Unit to validate
 * @returns {boolean} - Wether or not the unit is valid
 */
export const isUnitValid = (unit: Unit) => {
  return Boolean(unit.name && unit.name !== '')
}

/**
 * Get index of next section
 *
 * @param  {number}   curIndex - Current index
 * @param  {number}   maxIndex - Max index of sections
 * @returns {number} - Index of next section
 */
export const getNextIndex = (curIndex: number, maxIndex: number): number =>
  curIndex + 1 < maxIndex ? curIndex + 1 : maxIndex

/**
 * Get index of previous section
 *
 * @param  {number}   curIndex - Current index
 * @param  {number}   minIndex - Minimum index of sections
 * @returns {number} - Index of previous section
 */
export const getPrevIndex = (curIndex: number, minIndex: number = 0) =>
  curIndex - 1 > minIndex ? curIndex - 1 : minIndex

export const addressIsValid = (address: Address): boolean => {
  return Boolean(address.street && address.city && address.state && address.zip)
}

type CSVMetadata = { start: number; end: number; timezone: string }

export const generateUsageCSV = (rawUsage: UnitUsage[] | undefined, metadata: CSVMetadata): string => {
  if (!rawUsage) {
    return ''
  }

  const usageByDate = {
    TOTAL: {},
    ...rawUsage.reduce((acc, cur) => ({ ...acc, [cur.unit_name]: {} }), {})
  }

  rawUsage.forEach((unit) => {
    unit.devices.forEach((device) => {
      device.daily_usages.forEach((usageData) => {
        addCreate(usageByDate[unit.unit_name], usageData.date, Math.round(usageData.volume))
        addCreate(usageByDate.TOTAL, usageData.date, Math.round(usageData.volume))
      })
    })
  })

  const headers = `Date (${metadata.timezone}),Property Total (gal),"${Object.keys(usageByDate)
    .filter((k) => k !== 'TOTAL')
    .sort()
    .join(' (gal)","')} (gal)", ,start: ${metadata.start},end: ${metadata.end},timezone: ${metadata.timezone}\n`

  const anyDates = Object.keys(usageByDate.TOTAL).length

  const totalsRow =
    anyDates &&
    `Total ${format(parseISO(Object.keys(usageByDate.TOTAL)[0]), 'M/d/yyyy')} - ${format(
      // @ts-ignore - toReversed() not recognized
      parseISO(Object.keys(usageByDate.TOTAL).toReversed()[0]),
      'M/d/yyyy'
    )},${Object.values(usageByDate.TOTAL as number[]).reduce((acc: number, cur: number) => acc + cur, 0)},${Object.keys(
      usageByDate
    )
      .filter((unitId) => unitId !== 'TOTAL')
      .sort()
      .map((unitId) => Object.values(usageByDate[unitId] as number[]).reduce((acc: number, cur) => acc + cur, 0))
      .join(',')}\n`

  const body = Object.keys(usageByDate.TOTAL)
    .sort()
    .map((dateStr) => {
      return `${format(parseISO(dateStr), 'M/d/yyyy')},${usageByDate.TOTAL[dateStr]},${Object.keys(usageByDate)
        .filter((k) => k !== 'TOTAL')
        .sort()
        .map((k) => (!isNaN(usageByDate[k][dateStr]) ? usageByDate[k][dateStr] : 'Unknown'))
        .join(',')}`
    })
    .join('\n')

  return `${headers}${totalsRow || ''}${body}`
}

export const getUnitStatus = (unit: Unit): DeviceStatus => {
  let status: DeviceStatus = 'OKAY'
  if (unit?.devices?.length) {
    if (unit.devices.some((device) => device.device_status === 'ERROR')) {
      status = 'ERROR'
      return status
    }
    if (unit.devices.some((device) => device.device_status === 'WARNING')) {
      status = 'WARNING'
      return status
    }

    return status
  } else {
    return 'NO_DEVICES'
  }
}
